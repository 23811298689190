import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import OpenExitDoorSvg from "../img/OpenExitDoorSvg";
import axios from "axios";
import {message} from "antd";
import styled from "styled-components";
import {useIntl} from "react-intl";
const StyledNavLink = styled(NavLink)`
  &:hover {
      i {
        transition: .3s all;
        color: #0d4d87 !important;
        }
    }
`
const StyledButton = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    svg {
      polygon, path, ellipse {
        fill: #0d4d87;
        transition: .3s all;
      }
    }
  }

  svg {
    height: 27px;
    width: 27px;
    margin-left: 20px;
  }
`;


const Login = () => {
    const [border, setBorder] = useState("auth-border");
    const [caption, setCaption] = useState();
    const intl = useIntl();

    let ws = useRef();

    useEffect(() => {
        ws.current = new WebSocket(`${document.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/ws`);
        ws.current.onmessage = (event) => {
            let data = JSON.parse(event.data);

            if (data.Status) data.border = 'auth-border success';
            else data.border = 'auth-border';
            setBorder(data.border)
            setCaption(data.Value)
        };
    }, []);

    const handleOpenDoor = () => {
        axios(`/api/door`).then(() => {
            message.success(intl.formatMessage({id: "popup.doorOpened"}));
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    return (
        <div className="login">
            <div className="wrapper">
                <div className="logo">face auth <StyledNavLink to="/panel"><i className="fas fa-user-cog" onClick={() => {
                    ws.current.close();
                }}> </i></StyledNavLink>
                    <StyledButton onClick={handleOpenDoor}><OpenExitDoorSvg/></StyledButton>
                </div>
                <div className="view">
                    <div className="cam">
                        <div className={border}>
                            <div className="status">{caption}</div>
                        </div>
                        <img src="/api/cam" alt="cam stream"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;