import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import Button from "reactstrap/es/Button";
import {Field, reduxForm, SubmissionError} from 'redux-form';
import axios from "axios";
import * as usersAction from "../actions/usersAction";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import history from "../utils/history";
import {FormattedMessage} from "react-intl";


const renderField = ({input, label, type, className, classLabel, place, meta: {touched, error}}) => (
    <div>
        <label className={classLabel}>{label}</label>
        <div>
            <input {...input} placeholder={place} type={type} className={className}/>
            {touched && error && <span className={'form-error'}>{error}</span>}
        </div>
    </div>
);


class ContactForm extends React.Component {

    submit(values) {
        if (!values.name) {
            throw new SubmissionError({
                name: 'User name empty',
                _error: 'User name empty'
            })
        } else if (values.name.length < 1) {
            throw new SubmissionError({
                name: 'User name small',
                _error: 'User name small'
            })
        } else if (values.name.length > 100) {
            throw new SubmissionError({
                name: 'User name big',
                _error: 'User name big'
            })
        } else {
            let bodyFormData = new FormData();
            bodyFormData.set('caption', values.name);
            axios({
                url: '/api/person',
                method: 'POST',
                data: bodyFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.props.usersAction.loadUsers();
                this.props.cancel();
                history.push('/panel/' + res.data.Id);
            })
        }
    }

    render() {
        const {handleSubmit, submitting, cancel} = this.props;
        return (
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
                <div>
                    <Field
                        name="name"
                        label={<FormattedMessage id={"panel.form.name"}/>}
                        component={renderField}
                        type="text"
                        className={'form-field'}
                        classLabel={'form-label'}
                        place={'Example: Mark Twain'}
                    />
                </div>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'} type={'submit'} disabled={submitting}>Add</button>
                    <Button outline color="secondary" onClick={cancel} disabled={submitting}>Cancel</Button>
                </ModalFooter>
            </form>
        )
    }
}

ContactForm = reduxForm({
    form: 'contact'
})(ContactForm);

class RegModal extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}><FormattedMessage id={"panel.addUser"}/></ModalHeader>
                <ModalBody>
                    <ConnectedForm cancel={this.props.toggle}/>
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    usersAction: bindActionCreators(usersAction, dispatch)
});

const ConnectedForm = connect(null, mapDispatchToProps)(ContactForm);

export default RegModal;