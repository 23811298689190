import React, {useEffect, useState} from 'react';
import {message, TimePicker} from "antd";
import styled from "styled-components";
import Close from "../img/Close";
import moment from "moment";
import axios from "axios";
import {store} from "../index";
import {loadUsers} from "../actions/usersAction";
import {FormattedMessage, useIntl} from "react-intl";

const ScheduleItem = styled.div`
  display: flex;
  max-width: 400px;
  width: 100%;
  border: 1px solid #6feb93;
  max-height: 40px;
  align-items: center;
  padding: 10px;

  .day-name {
    flex: 1 1 auto;
  }

  .picker {
    margin: 0 10px;
  }

  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`;


const StyledSchedule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ScheduleItem}:not(:last-child) {
    border-bottom: none;
  }
`;

function Schedule({user: {Schedule: schedule_arr, Id}}) {
    const intl = useIntl();
    useEffect(() => {
        setTimes(Object.fromEntries(days.map((el, index) => {
            return [el, schedule_arr[index] ? moment(schedule_arr[index], "HH:mm") : null]
        })))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule_arr])

    const textDays = [<FormattedMessage id={"schedule.monday"}/>, <FormattedMessage id={"schedule.tuesday"}/>, <FormattedMessage id={"schedule.wednesday"}/>, <FormattedMessage id={"schedule.thursday"}/>, <FormattedMessage id={"schedule.friday"}/>,<FormattedMessage id={"schedule.saturday"}/>, <FormattedMessage id={"schedule.sunday"}/>];
    const days = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
    const [times, setTimes] = useState(Object.fromEntries(days.map((el, index) => {
        return [el, schedule_arr[index] ? moment(schedule_arr[index], "HH:mm") : null]
    })));

    const handleChange = (value, name) => {
        let newTimes = {...times, [name]: value};
        setTimes(newTimes);
        let timesArray = generateArrayOfTimes(newTimes);
        console.log(timesArray);
        saveSchedule(timesArray)
    }

    const generateArrayOfTimes = (timesObject) => {
        return Object.keys(timesObject).map(el => {
            return timesObject[el] ? timesObject[el].format("HH:mm") : "";
        });
    }

    const saveSchedule = (data) => {
        axios.post(`/api/person/${Id}`, {
            Schedule: data
        }).then(() => {
            store.dispatch(loadUsers());
            message.success(intl.formatMessage({id: "popup.succUpdated"}))
        }).catch(({response: {r_data}}) => {
            message.error(r_data)
        })
    }

    const handleClear = (name) => {
        let newTimes = {...times, [name]: null};
        setTimes(newTimes);
        let timesArray = generateArrayOfTimes(newTimes);
        saveSchedule(timesArray)
    }

    return (
        <StyledSchedule>
            {days.map((el, value) => (
                <ScheduleItem key={value}>
                    <div className="day-name">{textDays[days.indexOf(el)]}</div>
                    <div className="picker"><TimePicker allowClear={false} value={times[el]} onChange={v => handleChange(v, el)}
                                                        onSelect={_ => console.log(_)} format={"HH:mm"}/></div>
                    <Close onClick={_ => handleClear(el)}/>
                </ScheduleItem>
            ))}
        </StyledSchedule>
    );
}

export default Schedule;