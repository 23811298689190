import React, {useEffect} from "react";
import 'react-widgets/styles.css';
import * as settingsAction from "../actions/settingsActions";
import {connect} from "react-redux";
import axios from 'axios';
import {Button, Form, Input, InputNumber, message, Select} from "antd";
import {store} from "../index";
import styled from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .ant-row {
    margin-bottom: 5px;
    max-width: 300px;
    width: 100%;
    margin-right: 15px;
  }

  .group_block {
    display: flex;
    flex-direction: column;
  }

  .group_block .block:not(:last-child) {
    margin-bottom: 10px;
  }

  .ant-btn {
    background: #6feb93;
    border: none;
    color: #141e27;
    font-weight: bold;
    text-shadow: none;
  }

  .block {
    border: 2px solid #6feb93;
    position: relative;

    .ant-row {
      padding: 0 10px;
    }

    .ant-row:last-child {
      padding: 10px 10px;
    }

    h1 {
      color: #6feb93;
      font-size: 24px;
      border-bottom: 1px solid #6feb93;
      padding: 10px;
      margin-bottom: 10px;
    }

    &:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 50px;
      top: -2px;
      right: 20px;
      background: #141e27;
    }
  }
`;

const levels = [
    {text: 'Blank', value: 0},
    {text: 'Error', value: 1},
    {text: 'Info', value: 3},
    {text: 'Detail', value: 4},
    {text: 'Full', value: 5}
];
const {Option} = Select;

const SettingsForm = ({settings}) => {

    const [formRef] = Form.useForm();
    const intl = useIntl();
    useEffect(() => {
        store.dispatch(settingsAction.loadSettings())
    }, [])

    useEffect(() => {
        formRef.setFieldsValue(settings);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings])


    const handleSubmitForm = values => {
        axios.post(`/api/options`, values).then(() => {
            store.dispatch(settingsAction.loadSettings())
            message.success(intl.formatMessage({id: "popup.succUpdated"}))
        }).catch(({response: {data}}) => {
            message.error(data)
        })
        // axios({
        //     url: '/api/options',
        //     method: 'POST',
        //     data: {
        //         FlagDebug: values.FlagDebug.toString(),
        //         TypeLog: values.TypeLog.value ? values.TypeLog.value.toString() : values.TypeLog.toString(),
        //         Login: values.Login.toString(),
        //         Password: values.Password.toString(),
        //         LockAddress: values.LockAddress.toString(),
        //     }
        // }).then(_ => {
        //     store.dispatch(settingsAction.loadSettings())
        // });
    };


    return (
        <StyledForm onFinish={handleSubmitForm} form={formRef} layout={"vertical"} initialValues={settings}>
            {/*System*/}
            <div className="block">
                <h1>System</h1>
                {/*TypeLog*/}
                <Form.Item name={"TypeLog"} label={"Log type"}>
                    <Select>
                        {levels.map((el, value) => (
                            <Option key={value} value={el.value}>{el.text}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {/*FlagDebug*/}
                <Form.Item name={"FlagDebug"} label={"Flag debug"}>
                    <Select>
                        <Option value={true}>true</Option>
                        <Option value={false}>false</Option>
                    </Select>
                </Form.Item>
                {/*Login*/}
                <Form.Item name={"Login"} label={"Login"}>
                    <Input/>
                </Form.Item>
                {/*Password*/}
                <Form.Item name={"Password"} label={"Password"}>
                    <Input/>
                </Form.Item>
                {/*WebPort*/}
                <Form.Item name={"WebPort"} label={"Web port"}>
                    <InputNumber/>
                </Form.Item>
                {/*RtspSource*/}
                <Form.Item name={"RtspSource"} label={"Rtsp source"}>
                    <Input/>
                </Form.Item>
            </div>
            <div className={"group_block"}>
                {/*SMTP*/}
                <div className="block">
                    <h1>SMTP</h1>
                    {/*ServerSMTP*/}
                    <Form.Item name={"ServerSMTP"} label={"SMTP server"}>
                        <Input/>
                    </Form.Item>
                    {/*PortSMTP*/}
                    <Form.Item name={"PortSMTP"} label={"SMTP port"}>
                        <InputNumber/>
                    </Form.Item>
                    {/*LoginSMTP*/}
                    <Form.Item name={"LoginSMTP"} label={"SMTP Login"}>
                        <Input/>
                    </Form.Item>
                    {/*PassSMTP*/}
                    <Form.Item name={"PassSMTP"} label={"SMTP password"}>
                        <Input/>
                    </Form.Item>
                </div>
                {/*Door*/}
                <div className="block">
                    <h1>Door</h1>
                    {/*OpenDelaySec*/}
                    <Form.Item name={"OpenDelaySec"} label={"Delay open in sec"}>
                        <InputNumber/>
                    </Form.Item>
                    {/*Intercom*/}
                    <Form.Item name={"Intercom"} label={"Intercom"}>
                        <Input/>
                    </Form.Item>
                    {/*IntercomOptions*/}
                    <Form.Item name={"IntercomOptions"} label={"Intercom options"}>
                        <Input/>
                    </Form.Item>
                </div>
            </div>
            <div className={"group_block"}>
                {/*Remote*/}
                <div className="block">
                    <h1>Recognizer</h1>
                    {/*RemoteLogin*/}
                    <Form.Item name={"RemoteLogin"} label={"Remote login"}>
                        <Input/>
                    </Form.Item>
                    {/*RemotePassword*/}
                    <Form.Item name={"RemotePassword"} label={"Remote password"}>
                        <Input/>
                    </Form.Item>
                    {/*RemoteRecognizer*/}
                    <Form.Item name={"RemoteRecognizer"} label={"Remote recognizer"}>
                        <Input/>
                    </Form.Item>
                    {/*Tolerance*/}
                    <Form.Item name={"Tolerance"} label={"Tolerance"}>
                        <InputNumber/>
                    </Form.Item>
                </div>
                {/*Report*/}
                <div className="block">
                    <h1>Report</h1>
                    {/*ReportMail*/}
                    <Form.Item name={"ReportMail"} label={"Report mail"}>
                        <Input/>
                    </Form.Item>
                    {/*ReportPeriod*/}
                    <Form.Item name={"ReportPeriod"} label={"Report period"}>
                        <Select>
                            <Option value={"weekly"}>Weekly</Option>
                            <Option value={"daily"}>Daily</Option>
                            <Option value={"monthly"}>Monthly</Option>
                        </Select>
                    </Form.Item>
                </div>
                <Button type={"primary"} htmlType={"submit"}><FormattedMessage id={"settings.submit"}/></Button>
            </div>
        </StyledForm>
    )
}
class Settings extends React.Component {
    render() {
        return (
            <div className="settings">
                <SettingsFormConn/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.settings
    }
};


const SettingsFormConn = connect(mapStateToProps, null)(SettingsForm);

export default Settings;