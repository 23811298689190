import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form';
import { reduce as modal} from './modalReducer';
import { reduce as users} from './usersReducer';
import { reduce as settings} from './settingsReducer';

const reduxApp = combineReducers({
    form: formReducer,
    modal,
    users,
    settings
});

export default reduxApp;