import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import reduxApp from "./reducers";
import thunkMiddleware from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import LangWrapper from "./components/LangWrapper";


export let store = createStore(
    reduxApp,
    applyMiddleware(
        thunkMiddleware
    )
);



ReactDOM.render(
    <Provider store={store}>
        <LangWrapper>
                <App/>
        </LangWrapper>
    </Provider>,
    document.getElementById('root')
);

