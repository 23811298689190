import axios from "axios";

export function loadSettings(callback) {
    return dispatch => {

        dispatch({
            type: 'GET_SETTINGS_REQUESTED'
        });
        axios(
            {
                url: '/api/options',

                method: 'GET'
            }
        )
            .then(result => {
                dispatch({
                    type: 'GET_SETTINGS_OK',
                    data: result.data
                });
                callback();
            })
            .catch(result => {
                dispatch({
                    type: 'GET_SETTINGS_FAIL',
                    errors: result.statusText
                })
            })
    }
}