import React, {useContext} from 'react';
import {
    Navbar,
    NavbarBrand
} from 'reactstrap';
import Container from "reactstrap/es/Container";
import Row from "reactstrap/es/Row";
import {Link, NavLink} from 'react-router-dom'
import styled from "styled-components";
import {Context} from "./LangWrapper";

const AllUsers = styled(NavLink)`
  font-size: 28px;
  margin-right: 20px;
`;
const UnknownUsers = styled(NavLink)`
  font-size: 28px;
`;

const StyledLanguageChanger = styled.div`
  cursor: pointer;
  color: #177ddc;
  font-size: 26px;
  margin: 0 20px;
  text-transform: uppercase;
  user-select: none;
  transition: .3s all;

  &:hover {
    color: #0d4d87 !important;
  }
`;

function logout() {
    let xmlhttp;
    if (window.XMLHttpRequest) xmlhttp = new XMLHttpRequest();
    // else xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState === 4) window.location.href = "/";
    };
    xmlhttp.open("GET", "/api/person", true);
    xmlhttp.setRequestHeader("Authorization", "Basic YXNkc2E6");
    xmlhttp.send();
    return false;
}

const Navigation = () => {
    const langs = ['en', 'ru'];
    const context = useContext(Context);
    const switchLang = () => {
        const nextIndex = langs.indexOf(context.locale) + 1;
        nextIndex > langs.length - 1 ? context.changeLocale(langs[0]) : context.changeLocale(langs[nextIndex]);
    }

    return (
        <Navbar color="light" light expand="md">
            <Container>
                <Row>
                    <NavbarBrand tag={"div"}>Face Auth
                        <NavLink to="/settings"><i className="fas fa-cog"> </i></NavLink>
                        <StyledLanguageChanger onClick={switchLang}>{context.locale}</StyledLanguageChanger>
                        <AllUsers to="/panel"><i className="fas fa-users"></i></AllUsers>
                        <UnknownUsers to="/panel/strangers"><i className="fas fa-hiking"></i></UnknownUsers>
                        <Link to="#"><i className="fas fa-sign-out-alt" onClick={() => {
                            logout();
                        }}> </i></Link>
                    </NavbarBrand>
                </Row>
            </Container>
        </Navbar>
    );
}

export default Navigation;