import React from 'react';

function OpenExitDoorSvg(props) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
             y="0px"
             width="494.237px" height="494.237px" viewBox="0 0 494.237 494.237"
             xmlSpace="preserve">
            <polygon points="85.211,457.685 180.89,457.851 180.89,36.024 85.211,36.024" fill="#177ddc"/>
            <path d="M199.726,0v494.237l209.084-37.554l0.216-418.867L199.726,0z M385.829,437.447l-163.104,29.288V27.528L386.007,57.03
			L385.829,437.447z" fill="#177ddc"/>
            <ellipse cx="247.119" cy="247.119" rx="12.715" ry="16.545" fill="#177ddc"/>
        </svg>
    );
}

export default OpenExitDoorSvg;