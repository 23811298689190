import React from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {bindActionCreators} from "redux";
import * as modalAction from "../actions/modalAction";
import * as usersAction from "../actions/usersAction";
import {connect} from "react-redux";
import axios from "axios";

class PhotoModal extends React.Component {

    shot() {
        const id = window.location.pathname.split('/').pop();
        let link = `/api/person/${id}/pic/${this.props.modal.activePhoto}`;
        axios({
            url: link,
            method: 'GET',
        }).then(res => {
            this.props.usersAction.loadUsers();
            this.props.modalAction.togglePhoto();
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Add Photo</ModalHeader>
                <ModalBody>
                    <img src="/api/cam" className={'cam-image'} alt={'cam'}/>
                    <ModalFooter>
                        <button className={'btn btn-outline-primary'} type={'submit'}
                                onClick={this.shot.bind(this)}>SHOT
                        </button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    modalAction: bindActionCreators(modalAction, dispatch),
    usersAction: bindActionCreators(usersAction, dispatch),
});

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
};

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(PhotoModal);


export default ConnectedModal;