export function toggleModal() {
    return {
        type: "TOGGLE_MODAL"
    }
}

export function togglePhoto(id) {
    return {
        type: "TOGGLE_PHOTO",
        data: id
    }
}