const initialState = {
    load: false,
    open: false,
    photo: false,
    activePhoto: 0
};

export function reduce(state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_MODAL":
            return Object.assign({}, state, {
                open: !state.open
            });
        case "TOGGLE_PHOTO":
            return Object.assign({}, state, {
                photo: !state.photo,
                activePhoto: action.data
            });
        default:
            return state
    }
}