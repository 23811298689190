import React, {Component, useState} from 'react';
//bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import './sass/App.sass';
import Container from "reactstrap/es/Container";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {Button} from "reactstrap";
//components
import RegModal from "./components/RegModal";
import PeopleCard from "./components/PeopleCard";
import Navigation from "./components/Navigation";
//redux
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as modalAction from './actions/modalAction';
import * as usersAction from './actions/usersAction';
//library
import {Route, Router, Switch} from "react-router-dom";
import PhotoModal from "./components/PhotoModal";
import axios from "axios";
import Settings from "./components/Settings";
import Login from "./components/Login";
import Schedule from "./RightPanelViews/Schedule";
import History from "./RightPanelViews/History";
import history from "./utils/history";
import {FormattedMessage} from "react-intl";
import moment from "moment";

const UserContent = (props) => {
    const {users, id} = props,
        {deleteUser, loadUsers} = props.usersAction,
        {togglePhoto} = props.modalAction,
        user = users.find(current => current.Id === id);

    const delete_photo = (uid) => {
        const id = window.location.href.split('/').pop(),
            link = `/api/person/${id}/pic/${uid}`;
        axios({
            url: link,
            method: 'DELETE',
        }).then(_ => {
            loadUsers();
        });
    };

    const trigger_status = (status) => {
        let bodyFormData = new FormData();
        bodyFormData.set('value', (!status).toString());

        const id = window.location.href.split('/').pop(),
            link = `/api/person/${id}/state`;
        axios({
            url: link,
            method: 'POST',
            data: bodyFormData
        }).then(_ => {
            loadUsers();
        });
    };


    const [currentPage, setCurrentPage] = useState("photos");


    if (user) {
        const {Caption, Status, Pic} = user;

        const photos = <div className={'photos'}>
            {Status ? Pic.map((item, i) => {
                return <div className="photo" key={i}>
                    <div className="photo-hover">
                        <div className="add-photo">
                            <Button outline color="primary" onClick={_ => {
                                togglePhoto(i)
                            }}>shot</Button>
                            <Button outline color="primary" onClick={() => {
                                delete_photo(i)
                            }}>Delete</Button>
                        </div>
                    </div>
                    <img src={item} alt={'cam_photo'}/>
                </div>
            }) : <span className={'message'}><FormattedMessage id={"panel.userDisabled"}/></span>}
        </div>

        return (
            <Col xs={8} className={'user-content'}>
                <h2 className="name">{Caption}</h2>
                <div className={'buttons-content'}>
                    {Status ?
                        <Button outline color="warning" onClick={_ => {
                            trigger_status(Status)
                        }}><FormattedMessage id={"panel.disable"}/></Button> :
                        <Button outline color="success" onClick={_ => {
                            trigger_status(Status)
                        }}><FormattedMessage id={"panel.enable"}/></Button>
                    }
                    <Button outline color="danger" onClick={_ => {
                        deleteUser(id)
                    }}><FormattedMessage id={"panel.delete"}/></Button>
                    <Button outline color="info" onClick={_ => {
                        setCurrentPage("photos")
                    }}><FormattedMessage id={"panel.photos"}/></Button>
                    <Button outline color="primary" onClick={_ => {
                        setCurrentPage("schedule")
                    }}><FormattedMessage id={"panel.schedule"}/></Button>
                    <Button outline onClick={_ => {
                        setCurrentPage("history")
                    }}><FormattedMessage id={"panel.history"}/></Button>
                </div>


                {(() => {
                    switch (currentPage) {
                        case "photos":
                            return photos
                        case "schedule":
                            return <Schedule user={user}/>
                        case "history":
                            return <History user={user}/>
                        default:
                            return photos
                    }
                })()}
            </Col>
        );
    } else return (
        <Col xs={8} className={'user-content'}>
            <span className={'message'}>
                <i className="fas fa-arrow-left"> </i>
                Select or add a user
            </span>
        </Col>
    );
};

class RightPanel extends Component {

    componentDidMount() {
        if (this.props.showStrangers)
            this.props.usersAction.loadStrangers();
        else
            this.props.usersAction.loadUsers();
    }

    render() {
        const {users, strangers} = this.props;
        const {toggleModal, togglePhoto} = this.props.modalAction;
        const {open, photo} = this.props.modal;
        return (
            <div id="App">
                <Navigation/>
                <Container style={{display: 'flex', overflow: 'hidden'}}>
                    <Row style={{width: "100%"}}>
                        <Col xs={4} className={'people-list'}>
                            {!this.props.showStrangers && <div className={"p-card add-user"} onClick={toggleModal}>
                                <div className="p-content">
                                    <div className="image-wrapper">
                                        <i className="fas fa-user-plus"> </i>
                                    </div>
                                    <div className={'p-info'}>
                                        <p className="p-name"><FormattedMessage id={"panel.addUser"}/></p>
                                    </div>
                                </div>
                            </div>}
                            {this.props.showStrangers ? strangers?.map(({Id, Filename, Time}, i) => {
                                return <PeopleCard to={`/panel/strangers/${Id}`}
                                                   name={moment(Time).format("DD.MM.YY HH:mm ")} userid={Time} key={i}
                                                   photo={`/${Filename}`}/>
                            }) : users?.map(({Caption, Id, Pic, Status}, i) => {
                                return <PeopleCard to={`/panel/${Id}`} name={Caption} userid={Id} key={i} photo={Pic[0]}
                                                   status={Status}/>
                            })}
                        </Col>
                        {this.props.children}
                    </Row>
                </Container>
                <RegModal open={open} toggle={toggleModal}
                          className={'reg-modal'}/>
                <PhotoModal open={photo} toggle={togglePhoto}
                            className={'reg-modal'}/>
            </div>
        );
    }
}

const UserPage = (props) => {
    return <RightPanel>
        <ConnectedUser id={props.match.params.id}/>
    </RightPanel>
};

const Strangers = (props) => {
    return <RightPanel showStrangers={true}>
        {/*<ConnectedUser id={props.match.params.id}/>*/}
    </RightPanel>
};

let StrangersPage = (props) => {
    const {strangers} = props;

    return <RightPanel showStrangers={true}>
        <Col xs={8} className={'user-content'}>
            {/*<img src={`/${strangers?.[props.match.params.id]?.Filename}`} alt={"photo"}/>*/}
            <div className={'buttons-content'}>
                <Button outline color="danger" onClick={_ => {
                    axios.delete(`/api/unknowns/${props.match.params.id}`).then(() => {
                        history.push("/panel/strangers")
                        props.usersAction.loadStrangers();
                    })
                    // deleteUser(id)
                }}><FormattedMessage id={"panel.delete"}/></Button>
            </div>
            <div className={'photos'}>
                <div className="photo" style={{width: "auto", height: "auto"}}>
                    <img src={`/${strangers?.find(el => el.Id?.toString() === props.match.params.id)?.Filename}`}
                         alt={"full_photo"}/>
                </div>
            </div>
        </Col>
        {/*<ConnectedUser id={props.match.params.id}/>*/}
    </RightPanel>
};

const SettingsPage = () => {
    return <RightPanel>
        <Settings/>
    </RightPanel>
};

class App extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path='/panel' component={RightPanel}/>
                    <Route exact path='/settings' component={SettingsPage}/>
                    <Route exact path='/panel/strangers' component={Strangers}/>
                    <Route exact path='/panel/strangers/:id' component={StrangersPage}/>
                    <Route exact path='/panel/:id' component={UserPage}/>
                    <Route exact path='/' component={Login}/>
                </Switch>
            </Router>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        users: state.users.users,
        strangers: state.users.strangers
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalAction: bindActionCreators(modalAction, dispatch),
        usersAction: bindActionCreators(usersAction, dispatch)
    }
};

RightPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(RightPanel);

const ConnectedUser = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserContent);

StrangersPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(StrangersPage);

export default App;
