import axios from "axios";

export function loadUsers() {
    return dispatch => {

        dispatch({
            type: 'GET_USERS_REQUESTED'
        });
        axios(
            {
                url: '/api/person',

                method: 'GET'
            }
        )
            .then(result => {
                dispatch({
                    type: 'GET_USERS_OK',
                    data: result.data
                });
            })
            .catch(result => {
                dispatch({
                    type: 'GET_USERS_FAIL',
                    errors: result.statusText
                })
            })
    }
}

export function loadStrangers() {
    return dispatch => {

        dispatch({
            type: 'GET_STRANGERS_REQUESTED'
        });
        axios(
            {
                url: '/api/unknowns',

                method: 'GET'
            }
        )
            .then(result => {
                dispatch({
                    type: 'GET_STRANGERS_OK',
                    data: result.data
                });
            })
            .catch(result => {
                dispatch({
                    type: 'GET_STRANGERS_FAIL',
                    errors: result.statusText
                })
            })
    }
}

export function deleteUser(id) {
    return dispatch => {

        dispatch({
            type: 'DELETE_USER_REQUESTED'
        });
        axios(
            {
                url: `/api/person/${id}`,

                method: 'DELETE'
            }
        )
            .then(result => {
                dispatch({
                    type: 'DELETE_USER_OK',
                    data: id
                });
            })
            .catch(result => {
                dispatch({
                    type: 'GET_USER_FAIL',
                    errors: result.statusText
                })
            })
    }
}
