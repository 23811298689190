import React from 'react';
import {Link} from "react-router-dom";

class PeopleCard extends React.Component {
    render() {
        return (
            <div className={"p-card"}>
                <Link to={this.props.to}>
                    <div className="p-content">
                        <div className="image-wrapper">
                            <img src={this.props.photo} alt=""/>
                        </div>
                        <div className={'p-info'}>
                            <p className="p-name">{this.props.name}</p>
                            {this.props.status !== undefined && (this.props.status ?
                                <p className={'p-status'}><span className={"status active"}>active</span></p>
                                : <p className={'p-status'}><span className={"status disabled"}>disabled</span></p>)}
                        </div>
                    </div>
                </Link>
            </div>

        );
    }
}

export default PeopleCard;