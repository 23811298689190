import React, {useEffect, useState} from 'react';
import {IntlProvider} from "react-intl";
import Russian from '../lang/ru.json';
import English from '../lang/en.json';

export const Context = React.createContext();

function LangWrapper({children}) {
    const [locale, setLocale] = useState(localStorage.getItem("app_locale") || "en");
    const [messages, setMessages] = useState(Russian);

    useEffect(() => {
        changeLocale(locale);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const changeLocale = (lang) => {
        setLocale(lang);
        localStorage.setItem("app_locale", lang);
        switch (lang) {
            case "en":
                return setMessages(English);
            case "ru":
                return setMessages(Russian);
            default:
                return setMessages(English);
        }
    }

    return (
        <Context.Provider value={{locale, changeLocale}}>
            <IntlProvider locale={locale} messages={messages}>
                {children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default LangWrapper;