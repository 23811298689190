const initialState = {
    load: false,
    users: [],
    strangers: []
};

export function reduce(state = initialState, action) {
    switch (action.type) {
        case "GET_USERS_REQUESTED":
            return Object.assign({}, state, {
                load: true
            });
        case "GET_STRANGERS_REQUESTED":
            return Object.assign({}, state, {
                load: true
            });
        case "GET_USERS_OK":
            return Object.assign({}, state, {
                load: false,
                users: action.data
            });
        case "GET_STRANGERS_OK":
            return Object.assign({}, state, {
                load: false,
                strangers: action.data
            });
        case "DELETE_USER_REQUESTED":
            return Object.assign({}, state, {
                load: true
            });
        case "DELETE_USER_OK":
            let users = [...state.users];
            for (let i = 0; i < users.length; i++) {
                if (action.data === users[i]['Id']) {
                    users.splice(i, 1)
                }
            }
            return Object.assign({}, state, {
                load: false,
                users: users
            });
        default:
            return state
    }
}