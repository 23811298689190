import React from 'react';
import styled from "styled-components";
import moment from "moment";
import {FormattedMessage} from "react-intl";

const HistoryColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .header, .value {
    padding: 10px;
    height: 45px;
    border-right: 1px solid #6feb93;
  }
  .header {
    border-bottom: 1px solid #6feb93;
  }
  .value:not(:last-child) {
    border-bottom: 1px solid #6feb93;
  }
`;

const StyledHistory = styled.div`
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #a3a3a3;

  }
  
  display: flex;
  border: 1px solid #6feb93;
  border-right: none;
  
`;

function History({user: {Attendance}}) {
    return (
        <StyledHistory>
            <HistoryColumn className="date">
                <div className="header">
                    <FormattedMessage id={"history.date"}/>
                </div>
                {Attendance?.map((el, index)=><div key={index} className="value">
                    {moment(el.Attend).format("DD.MM.YYYY")}
                </div>)}
            </HistoryColumn>
            <HistoryColumn className="time">
                <div className="header">
                    <FormattedMessage id={"history.arrivalTime"}/>
                </div>
                {Attendance?.map((el, index)=><div key={index} className="value">
                    {moment(el.Attend).format("HH:mm")}
                </div>)}
            </HistoryColumn>
            <HistoryColumn className="lateness">
                <div className="header">
                    <FormattedMessage id={"history.lateness"}/>
                </div>
                {Attendance?.map((el, index)=><div key={index} className="value">
                    {el.Missed ? "–" : <FormattedMessage id={"history.min"}>{txt=> `${el.LatenessMinute} ${txt}.`}</FormattedMessage>}
                </div>)}
            </HistoryColumn>
        </StyledHistory>
    );
}

export default History;