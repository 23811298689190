const initialState = {
    FlagDebug: false,
    TypeLog: 0,
    Login: 'admin',
    Password: 'admin',
    LockAddress: 0
};

export function reduce(state = initialState, action) {
    switch (action.type) {
        case 'GET_SETTINGS_OK':
            console.log(action.data);
            return Object.assign({}, state, action.data);
        default:
            return state;
    }
}